import React from "react";

import { Spinner } from "react-bootstrap";

const Loading = () => (
  <Spinner animation="grow" className="d-block mx-auto" role="status">
    <span className="visually-hidden">Loading...</span>
  </Spinner>
);

export default Loading;
