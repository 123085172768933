import React, { useState, useEffect } from "react";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useCart } from "react-use-cart";

import { Container } from "react-bootstrap";

import Layout from "../components/common/Layout";
import Loading from "../components/blocks/status/Loading";
import Success from "../components/blocks/status/Success";
import Anchor from "../components/elements/Anchor";
import { useShop } from "../context/ShopContext";
import { isBrowser } from "../helpers/checkEnvironment";

import "../styles/pages/signup.scss";

const Status = () => {
  const [status, setStatus] = useState("loading");
  const [cart] = useState(useCart());
  const { stripePromise } = useShop();

  if (!isBrowser) return null;

  // http://localhost:8000/status?payment_intent=pi_3JwPrDFXaZ9gl2NF2Qyst63M&payment_intent_client_secret=pi_3JwPrDFXaZ9gl2NF2Qyst63M_secret_r4m7g6taG4pLNO2r7KpwEvoYz&redirect_status=succeeded
  const clientSecret = new URLSearchParams(window.location.search).get(
    "payment_intent_client_secret"
  );

  // TODO: check if cart isEmpty
  if (!clientSecret) {
    navigate("/404", { replace: true });
    return null;
  }

  useEffect(() => {
    stripePromise.then((stripe) =>
      // payment_status: succeeded, processing, requires_payment_method
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        setStatus(paymentIntent.status);
        cart.emptyCart();
      })
    );
  }, [cart, clientSecret, stripePromise]);

  return (
    <Layout disableAnnouncement disableHeader disableFooter disableCartBar>
      <div className="pb-5 bg-primary" aria-hidden="true" />
      <StaticImage
        src="../images/flow-veggies-top.png"
        className="signup-veggies-top low"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <StaticImage
        src="../images/flow-veggies-bottom.png"
        className="signup-veggies-bottom"
        alt=""
        layout="fullWidth"
        placeholder="tracedSVG"
      />
      <Container className="mw-1000 py-5 py-lg-7 text-center">
        <Anchor href="/">
          <StaticImage
            src="../images/favicon.png"
            alt="Hello Chef"
            width={72}
            className="mb-5"
            placeholder="tracedSVG"
          />
        </Anchor>
        {status === "loading" && <Loading />}
        {status === "succeeded" && <Success cart={cart} />}
      </Container>
    </Layout>
  );
};

export default Status;
