import React from "react";
import { navigate } from "gatsby";

import { Alert, Button } from "react-bootstrap";

import Anchor from "../../elements/Anchor";
import { useCustomer } from "../../../context/CustomerContext";
import { dateFormat, Currency } from "../../../helpers";

const Success = ({ cart }) => {
  if (cart.isEmpty) {
    navigate("/", { replace: true });
    return null;
  }

  const { customer } = useCustomer();

  return (
    <>
      <h1 className="visually-hidden">Ολοκλήρωση παραγγελίας</h1>
      <ul className="list-unstyled mb-5">
        <li className="mb-2">
          <span className="fw-bold text-primary">Ημερομηνία παραλαβής: </span>
          <span>{dateFormat(cart.metadata?.date)}</span>
        </li>
        <li className="mb-2">
          <span className="fw-bold text-primary">Ώρα παράδοσης: </span>
          <span>{cart.metadata?.time || "--:--"}</span>
        </li>
        <li className="mb-2">
          <span className="fw-bold text-primary">Γεύματα: </span>
          <span>{cart.metadata?.box?.meals || "-"}</span>
        </li>
        <li className="mb-2">
          <span className="fw-bold text-primary">Έκπτωση: </span>
          <span>-{cart.metadata?.box?.discount || "0"}%</span>
        </li>
        <li className="mb-2">
          <span className="fw-bold text-primary">Χρέωση:</span>{" "}
          <span>ανά εβδομάδα</span>
        </li>
        <li className="mb-2">
          <span className="fw-bold text-primary">Συνδρομή: </span>
          <span>
            <Currency value={cart.metadata?.box?.price} />
          </span>
        </li>
      </ul>
      {(!customer || !customer.jwt) && (
        <Alert variant="primary" className="mw-600 mx-auto mb-4 text-center">
          Ολοκληρώστε τον λογαριασμό σας για να μπορείτε να επεξεργαστείτε τις
          πληροφορίες σας και να δείτε το ιστορικό σας.
          <hr />
          <Button as={Anchor} href="/account/register/">
            Ολοκλήρωση Προφίλ
          </Button>
        </Alert>
      )}
      <h2 className="fw-normal text-primary mb-5">
        Ευχαριστούμε που μας προτιμήσατε.
        <br />
        Καλή όρεξη!
      </h2>
      {customer?.jwt && (
        <div className="text-center">
          <Button
            as={Anchor}
            href="/account/dashboard/"
            title="Μετάβαση στον λογαριασμό"
            className="btn btn-primary"
          >
            Ο λογαριασμός μου
          </Button>
        </div>
      )}
    </>
  );
};

export default Success;
